import { ObjectType } from '@/app/constants/typedef';
import { Reply } from '../feeds/ReplyModel';
import { DateType } from '../date';
import { FeedModel, IFeeds } from '../feeds/FeedModel';
import { CaptionForChallenges } from '../captions/CaptionForChallenges';

export type ChallengeModeType = 'periodic' | 'continuous'
export enum ChallengeMode {
    PERIODIC = 'periodic',
    CONTINUOUS = 'continuous'
}
export interface Challenge extends IFeeds {
    name: string;
    mode: string;
    publishAt: DateType;
    startAt: DateType;
    endAt: DateType;
    winnersAnnouncedAt: DateType;
    isBookmark: boolean;
    isPaidParticipation: boolean;
    participationFee: number;
    allowMultipleParticipation: boolean;
    showParticipantCount: boolean;
    allowComments: boolean;
    winnerAnnouncementStatus: string;
    participantCount: number;
    captions: CaptionForChallenges[];
    template: string;
}

class ChallengeModel extends FeedModel implements Challenge {
    public name: string;
    public mode: string;
    public publishAt: DateType;
    public startAt: DateType;
    public endAt: DateType;
    public isLike: boolean;
    public isLeader: boolean;
    public isEdited: boolean;
    public isBookmark: boolean;
    public comment?: Reply;
    public bookmarkCount: number;
    public winnersAnnouncedAt: DateType;
    public isPaidParticipation: boolean;
    public participationFee: number;
    public allowMultipleParticipation: boolean;
    public showParticipantCount: boolean;
    public allowComments: boolean;
    public winnerAnnouncementStatus: string;
    public participantCount: number;
    public captions: CaptionForChallenges[];
    public template: string;
    public url?: string | null;
    // public comments: Reply[] | [];

    constructor(obj: ObjectType) {
        super(obj);
        this.name = obj.name;
        this.mode = obj.mode;
        this.publishAt = obj.publish_at;
        this.startAt = obj.start_at;
        this.isEdited = obj.is_edited;
        this.endAt = obj.end_at;
        this.isLike = obj.is_like;
        this.isLeader = obj.is_leader;
        this.comment = obj.comment;
        this.url = obj.url;
        this.isBookmark = obj.is_bookmark;
        this.bookmarkCount = obj.bookmark_count;
        this.winnersAnnouncedAt = obj.winners_announced_at;
        this.isPaidParticipation = obj.is_paid_participation;
        this.participationFee = obj.participation_fee;
        this.allowMultipleParticipation = obj.allow_multiple_participation;
        this.showParticipantCount = obj.show_participant_count;
        this.allowComments = obj.allow_comments;
        this.winnerAnnouncementStatus = obj.winner_announcement_status;
        this.participantCount = obj.participant_count;
        this.captions = obj.captions;
        this.template = obj.template;
    }
}

export default ChallengeModel;
