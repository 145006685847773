'use client';
import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { getCookie } from 'cookies-next';
import { decrypt } from '../helpers/cryptoMethods';

interface Tokens {
    access: string;
    refresh: string;
}
// Create axios instance.
const axiosInstance = axios.create({
    baseURL: process.env.BASE_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        // Authorization: 'Bearer ' +
    },
});

let accessToken: string = '';
let refreshToken: string = '';
let isRefreshing: boolean = false;
let refreshSubscribers: ((token: string) => void)[] = [];

export const setRefreshToken = (tk: string): void => {
    refreshToken = tk;
};

export const setAccessToken = (tk: string): void => {
    accessToken = tk;
};

const subscribeTokenRefresh = (cb: (token: string) => void): void => {
    refreshSubscribers.push(cb);
};

export const setToken = ({ access, refresh }: Tokens): void => {
    if (access) accessToken = access;
    if (refresh) refreshToken = refresh;
};

const onRefreshed = (token: string): void => {
    refreshSubscribers.forEach((cb) => cb(token));
};

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
    headers: AxiosRequestHeaders;
}

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
    (config): AdaptAxiosRequestConfig => {
        config.headers.Authorization = `Bearer ${getCookie('_access') ? JSON.parse(decrypt(getCookie('_access') || '', process.env.SECRET_KEY)) : accessToken}`;
        return config;
    },
    (error): any => {
        return Promise.reject(error);
    }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    async function (error: any) {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && originalRequest?.url?.indexOf('/login') === -1) {
            return Promise.reject(error?.response ? error?.response : error);
        } else if (error.response && error.response.status === 410) {
            return Promise.reject(error?.response ? error?.response : error);
        } else if (error.response && error.response.status === 403) {
            return Promise.reject(error?.response ? error?.response : error);
        } else if (error?.code !== 'ERR_CANCELED' && error?.code === 'ERR_NETWORK') {
            return Promise.reject({ ...error, status: 500, msg: '서버에 문제가 발생했습니다.<br/> 나중에 다시 시도하세요.' });
        }
        // return Promise.reject(error);
        // if (error?.response?.status === 401 && originalRequest?.url?.indexOf('/login') === -1 && !originalRequest._retry) {
        //     originalRequest._retry = true;
        //     // if (!isRefreshing) {
        //     //     isRefreshing = true;
        //     //     try {
        //     //         const res = await axios({
        //     //             baseURL: process.env.BASE_URL,
        //     //             url: 'api/auth/refresh-token',
        //     //             method: 'post',
        //     //             // data: { token: refreshToken || getCookie('refreshToken') }
        //     //         });
        //     //         accessToken = res.data.accessToken;
        //     //         refreshToken = res.data.refreshToken;
        //     //         //   setCookieUser(res.data);

        //     //         isRefreshing = false;
        //     //         onRefreshed(accessToken);
        //     //     } catch (e) {
        //     //         return Promise.reject(e);
        //     //     }
        //     // }
        //     const retryOrigReq = new Promise<AxiosResponse>((resolve, reject) => {
        //         subscribeTokenRefresh((token) => {
        //             // replace the expired token and retry
        //             originalRequest.headers['Authorization'] = 'Bearer ' + token;
        //             resolve(axios(originalRequest));
        //         });
        //     });
        //     throw error
        //     return retryOrigReq;
        // }

        return Promise.reject(error?.response?.data ? error?.response?.data : error);
    }
);

export default axiosInstance;
